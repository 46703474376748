import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
    <Layout currentPage="notfound">
        <SEO title="404: Not found" />
        <div className="center">
            <h1 className="grey-text text-lighten-1" style={{ marginTop: "60px" }}>NOT FOUND</h1>
            <p className="white-text" style={{ marginBottom: "160px" }}>
                You just hit a route that doesn&#39;t exist... the sadness.
            </p>
        </div>
    </Layout>
);

export default NotFoundPage;
